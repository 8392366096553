import React from 'react';

export default function TopNav({ title, onMenuClick = () => {} }) {
	return (
	<div id="headerToggle">
		<a
		href="/#"
		aria-label="toggle navigation drawer"
		className="toggle"
		onClick={e => {
			e.preventDefault();
			onMenuClick();
		}}
		>
		{' '}
		</a>
	</div>
	);
}
