import React from 'react';
import Scrollspy from 'react-scrollspy';
import Scroll from '../Scroll';

export default function Nav({ sections = [] }) {
	return (
		<nav id="nav">
			<Scrollspy
				items={sections.map(s => s.id)}
				currentClassName="active"
				offset={-300}
			>
				{sections.map(s => {
					return (
						<li key={s.id}>
							<Scroll type="id" element={s.id}>
								<a href={`#${s.id}`} id="top-link" aria-label={s.name}>
									<span className={`icon ${s.icon}`}>{s.name}</span>
								</a>
							</Scroll>
						</li>
					);
				})}
			</Scrollspy>
		</nav>
	);
}
