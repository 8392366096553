module.exports = {
	siteTitle: 'Naman Sancheti',
	manifestName: 'Portfolio_Naman_Sancheti',
	manifestShortName: 'Portfolio', // max 12 characters
	manifestStartUrl: '/',
	manifestBackgroundColor: '#6daba9',
	manifestThemeColor: '#6daba9',
	manifestDisplay: 'standalone',
	manifestIcon: 'src/assets/images/manifest_icon.png',
	authorName: 'Naman Sancheti',
	heading: 'Sr. Front-End Engineer',
	// social
	socialLinks: [
		{
			icon: 'fa-github',
			name: 'Github',
			url: 'https://github.com/namansancheti',
		},
		{
			icon: 'fa-linkedin',
			name: 'LinkedIn',
			url: 'https://in.linkedin.com/in/namansancheti',
		},
		{
			icon: 'fa-twitter',
			name: 'Twitter',
			url: 'https://twitter.com/namans_26',
		},
		{
			icon: 'fa-youtube-play',
			name: 'YouTube',
			url: 'https://www.youtube.com/playlist?list=PLKzEFnvOkNmavp43YRuzZKrm2HJjWHO_b'
		},
		{
			icon: 'fa-medium',
			name: 'Medium',
			url: 'https://medium.com/@naman.sancheti'
		},
		{
			icon: 'fa-stack-overflow',
			name: 'Stack Overflow',
			url: 'https://stackoverflow.com/users/3709792/naman-sancheti',
		},
		{
			icon: 'fa-envelope',
			name: 'Email',
			url: 'mailto:naman.sancheti@gmail.com',
		},
	],
};
